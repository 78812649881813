<template lang="pug">
.faq
  markdown-view(:url='"/docs/faq.md"')
</template>

<script>
import MarkdownView from '@/components/MarkdownView.vue'
export default {
  components: {
    MarkdownView
  }
}
</script>

<style lang="sass" scoped>
@import "@/assets/variables.sass"
</style>
